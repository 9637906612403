<script setup lang="ts">
import SvgIcon from "~/components/Common/SvgIcon.vue";

const props = defineProps({
  name: {
    type: String,
  },
  default: {
    type: Boolean,
    default: true,
  },
  active: {
    type: Boolean,
    default: false,
  },
});

</script>

<template>
  <button :class="[props.default ? 'svg_button__button_blue' : 'svg_button__button_red']" v-bind="$attrs" v-if="name">
    <SvgIcon class="svg_button__img" :name="name"></SvgIcon>
  </button>
</template>

<style scoped>
.svg_button__button_blue {
  @apply flex items-center justify-center rounded-[12px];
  box-sizing: border-box;
  border: 1px solid var(--primary-blue-color);
  color: var(--primary-blue-color);
  height: 40px;
  width: 40px;

  &:hover {
    background-color: var(--grey-light);
    border-color: var(--blue-dark);
    color: var(--blue-dark);
  }

  &:active  {
    background-color: var(--grey-lightest);
  }

  &:disabled {
    border: 1px solid var(--grey-medium-light);
    color: var(--grey-medium-light);
  }
}

.svg_button__button_red {
  @apply flex items-center justify-center rounded-[12px];
  box-sizing: border-box;
  border: 1px solid var(--red-dark);
  color: var(--red-dark);
  height: 40px;
  width: 40px;

  &:hover {
    background-color: var(--grey-light);
    border-color: var(--red-main);
    color: var(--red-main);
  }

  &:active  {
    background-color: var(--grey-lightest);
  }

  &:disabled {
    border: 1px solid var(--grey-medium-light);
    color: var(--grey-medium-light);
  }
}

.svg_button__img {
  width: 24px;
  height: 24px;
}
</style>
